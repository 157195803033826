@import "./colors.scss";

$size: 2.5rem;

$color: $gray-300;
// $hover-color: $gray-300;
$checked-color: $success;

.k-chip {
  cursor: pointer;
  border-radius: 5px;
  width: $size;
  height: $size;
  border: 1px solid $color;
  color: $color;
  color: $gray-300;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 150ms ease-in-out;

  &.checked {
    border-color: $checked-color;
    color: $checked-color;
  }

  &:hover {
    &:hover:not(.checked) {
      background-color: rgba($color, 0.05);
    }
  }
}
