.k-verse-selector {
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  & > * {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
