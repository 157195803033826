@import "./colors.scss";

/*! Import Bootstrap 4 variables */
// @import "bootstrap/functions";
// @import "bootstrap/variables";
@import "../../node_modules/bootstrap/scss/functions.scss";
@import "../../node_modules/bootstrap/scss/variables.scss";

$link-hover-color: darken($link-color, 20%);
$navbar-dark-hover-color: $white;
$navbar-light-hover-color: $gray-800;
$navbar-light-active-color: $gray-800;
$input-bg: $gray-300;
$card-border-color: rgba($black, 0.6);
$card-cap-bg: lighten($gray-800, 10%);
$card-bg: lighten($gray-700, 4%);
$nav-divider-color: $gray-500;
$nav-tabs-link-active-color: $gray-200;
$list-group-bg: lighten($body-bg, 5%);
$list-group-border-color: rgba($black, 0.6);
$list-group-hover-bg: lighten($body-bg, 10%);
$list-group-active-color: $white;
$list-group-active-bg: $list-group-hover-bg;
$list-group-active-border-color: $list-group-border-color;
$list-group-disabled-color: $gray-800;
$list-group-disabled-bg: $black;
$list-group-active-color: #fff;
$list-group-action-color: #eee;
$list-group-action-hover-color: #fff;
$component-active-color: #eee;

$modal-content-bg: $gray-800;
$jumbotron-bg: $gray-900;
$dropdown-bg: $gray-800;
$dropdown-link-color: $gray-200;
$breadcrumb-bg: $gray-900;
$pagination-bg: $gray-900;
$progress-bg: $gray-300;

$input-bg: $gray-800;
$input-focus-bg: $gray-900;
$input-disabled-bg: #222;
$input-color: $white;
$input-focus-color: $gray-200;

$alert-bg-level: -2;
$alert-border-level: -4;
$alert-color-level: -8;

// @import "bootstrap";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

// @each $color, $value in $theme-colors {
//   @include table-row-variant($color, theme-color-level($color, -2));
// }

.card-wrapper {
  padding: 2.5rem 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .sub-header {
    display: flex;
    align-items: center;
    justify-content: center;
    & > * {
      margin-left: 1rem;
    }
  }
}
.secondary-actions {
  display: flex;
  justify-content: space-between;
}

.main-actions {
  button {
    height: 3rem;
  }
}

.verse-container {
  display: flex;
  flex-flow: row wrap;
  label {
    padding: 0.5rem;
    input {
      margin-right: 0.1rem;
    }
  }
}
