.app-card {
  border: 2px solid #ddd;
  height: 15rem;
  border-radius: 3rem;
  overflow: hidden auto;
  padding: 2rem;
  font-size: 150%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  & > div {
    flex: 1;
  }
}
