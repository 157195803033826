$white: #ddd;
$gray-300: #6e6e6f;
$gray-400: #444445;
$gray-700: #2d2d2f;
$gray-700: rgba(50, 50, 50, 0.9);
$gray-800: #1d1d1f;
$gray-900: #0e0e0f;
$primary: #00fdfd;
$secondary: #303030;
$success: #67d870;
$danger: #ff304f;
$info: #0f81c7;
$warning: #f0df0b;
$light: #e8e8e8;
$dark: #000000;
$enable-shadows: true;

$body-bg: #16181d;
$body-color: #cccccc;
$link-color: #f0f0f0;
